import * as S from "./style";
import { GlobalStyle } from "global-style";
import { Navigate, RouterProvider } from "react-router-dom";
import { typedCreateBrowserRouter } from "utils/router";
import { NotificationQueueProvider } from "context/NotificationQueue";
import {
    CustomerDataProvider,
    useCustomerData,
} from "customer/context/CustomerData";
import { SessionProvider, LoginType } from "context/Session";
import LoginWeb3 from "components/LoginWeb3";
import ErrorMessage from "components/ErrorMessage";
import Dashboard from "components/Dashboard";
import Transactions, {
    transactionDataLoader,
} from "customer/routes/Transactions";
import Money from "components/icons/Money";
import Chart from "components/icons/Chart";
import Question from "components/icons/Question";
import Contact from "components/icons/Contact";
import { MenuData, MenuType } from "components/Menu";
import Confirmed from "customer/routes/Transactions/Confirmed";
import Upcoming from "customer/routes/Transactions/Upcoming";
import Loading from "components/Loading";
import Authorizations from "customer/routes/Authorizations";
import AuthorizationDetails from "customer/routes/AuthorizationDetails";
import { ModalProvider } from "context/ModalProvider";
import WalletContextProvider from "context/Wallet";
import { WalletConnectedProvider } from "context/Wallet/WalletConnectedContext";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";
import { clarity } from "react-microsoft-clarity";

if (!clarity.hasStarted()) clarity.init("nk4e5a8fli");

const menus: MenuData[] = [
    {
        id: `primary-menu`,
        type: MenuType.Main,
        items: [
            {
                path: `/transactions`,
                icon: (
                    <Money
                        fill="inherit"
                        height={`1.25rem`}
                        width={`1.25rem`}
                        name="Transactions"
                    />
                ),
                label: `Transactions`,
            },
            {
                path: `/authorizations`,
                icon: (
                    <Chart
                        fill="inherit"
                        height={`1.25rem`}
                        width={`1.25rem`}
                        name="Authorizations"
                    />
                ),
                label: `Authorizations`,
            },
        ],
    },
    {
        id: `resources`,
        heading: `Resources`,
        type: MenuType.Bottom,
        items: [
            {
                path: import.meta.env.VITE_LOOP_DOCS_FAQ || ``,
                icon: (
                    <Question
                        fill="inherit"
                        height={`1.25rem`}
                        width={`1.25rem`}
                        name="Frequently asked questions"
                    />
                ),
                label: `FAQs`,
            },
            {
                path: `mailto:${import.meta.env.VITE_EMAIL_HELP}`,
                icon: (
                    <Contact
                        fill="inherit"
                        height={`1.25rem`}
                        width={`1.25rem`}
                        name="Contact us"
                    />
                ),
                label: `Contact Us`,
            },
        ],
    },
];

/* RP: LC-657
    This is a temporary solution to theming our application. Similar temporary
    code exists in other files, search "LC-657" to find other instances */
const theme = `light`;

const router = typedCreateBrowserRouter(
    [
        {
            path: "/login",
            element: (
                <WalletContextProvider requiresDynamicLogin={false}>
                    <SessionProvider loginType={LoginType.WEB3}>
                        <LoginWeb3 />
                    </SessionProvider>
                </WalletContextProvider>
            ),
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    path: "*",
                    element: <Navigate to="/login" />,
                },
            ],
        },
        {
            path: "/",
            element: (
                <WalletContextProvider>
                    <SessionProvider loginType={LoginType.WEB3}>
                        <WalletConnectedProvider>
                            <CustomerDataProvider>
                                <ModalProvider>
                                    <Dashboard
                                        theme={theme}
                                        menus={menus}
                                        wallet={true}
                                        dataLoaders={[useCustomerData]}
                                    ></Dashboard>
                                </ModalProvider>
                            </CustomerDataProvider>
                        </WalletConnectedProvider>
                    </SessionProvider>
                </WalletContextProvider>
            ),
            errorElement: <ErrorMessage>404</ErrorMessage>,
            children: [
                {
                    index: true,
                    element: <Navigate to="/transactions" />,
                },
                {
                    path: "/transactions",
                    handle: { heading: "Transactions" },
                    element: <Transactions />,
                    children: [
                        {
                            index: true,
                            element: <Navigate to="confirmed" />,
                        },
                        {
                            path: "confirmed",
                            loader: transactionDataLoader,
                            element: <Confirmed />,
                        },
                        {
                            path: "upcoming",
                            loader: transactionDataLoader,
                            element: <Upcoming />,
                        },
                    ],
                },
                {
                    path: "/authorizations",
                    handle: {
                        heading: "Authorizations",
                        tip: `Entities with which you have either a current allowance or past allowance with no record of cancelation.`,
                        description: `Loop allows you to fully control interactions with your wallet. Using the allowance function, you can limit the most a company can bill. You can change or revoke this amount at any time by clicking "edit" beside the token below.`,
                    },
                    element: <Authorizations />,
                },
                {
                    path: "/authorizations/details/:entityId",
                    handle: {
                        heading: (
                            <>
                                <S.BackToTx fallback={`/authorizations`} />
                                Manage authorization
                            </>
                        ),
                        tip: `Entities with which you have either a current allowance or past allowance with no record of cancelation.`,
                    },
                    element: <AuthorizationDetails />,
                },
                {
                    path: "*",
                    element: <Navigate to="/" />,
                },
            ],
        },
    ],
    {
        future: {
            v7_normalizeFormMethod: true,
        },
    }
);

const CustomerPortal = () => {
    return (
        <>
            <NotificationQueueProvider>
                <GlobalStyle />
                <RouterProvider router={router} fallbackElement={<Loading />} />
            </NotificationQueueProvider>
            <TawkMessengerReact
                propertyId="66a8d81a32dca6db2cb76cf0"
                widgetId="1i475mnhk"
            />
        </>
    );
};

export default CustomerPortal;
