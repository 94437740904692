import { Ref, forwardRef, useCallback, useState } from "react";
import { PendingTransactionDetails } from "api";
import { availableNetworks } from "default-variables";
import { JsonRpcProvider, TransactionReceipt } from "ethers";
import { toNetworkHex } from "utils/numbers";
import { useEffectOnce } from "hooks/useEffectOnce";
import { useGetNetworks } from "hooks/useGetNetworks";
import { getNetworkName } from "company/utils/networks";
import { toSentence } from "utils/strings";
import Spinner from "components/Spinner";
import FailedDataFetchingMessage from "company/components/FailedDataFetchingMessage";
import LoadingBox from "components/LoadingBox";
import Anchor from "components/Anchor";
import Checkmark from "components/icons/Checkmark";
import colors from "theme/colors";
import * as S from "./styles";
import Close from "components/icons/Close";

interface SelfServeDeployingContractsProps {
    pendingTransactionsDetails: PendingTransactionDetails[];
    onAllContractsDeployed: () => void;
}

export type SelfServeDeployingContractsRef = {};

function SelfServeDeployingContracts(
    {
        pendingTransactionsDetails,
        onAllContractsDeployed,
    }: SelfServeDeployingContractsProps,
    ref: Ref<SelfServeDeployingContractsRef>
) {
    const [contractsDeploymentError, setContractsDeploymentError] =
        useState<boolean>(false);

    const [contractsTransactionReceipts, setContractsTransactionReceipts] =
        useState<TransactionReceipt[]>([]);

    const { networks, getNetworksIsError, getNetworksIsLoading } =
        useGetNetworks();
    const networkIds = pendingTransactionsDetails.map((t) => t.networkId);

    const contractsDeployingOnNetworks = networks.filter((network) =>
        networkIds.includes(network.id)
    );

    const networkNames = contractsDeployingOnNetworks.map((network) =>
        getNetworkName(network)
    );

    const allContractsDeployed =
        pendingTransactionsDetails.length ===
        contractsTransactionReceipts.length;

    const waitForContractDeployed = useCallback(
        async (networkId: number, transactionHash: string) => {
            const network = availableNetworks.find(
                (network) => network.networkId === toNetworkHex(networkId)
            );

            if (!network) return;

            const provider = new JsonRpcProvider(network.rpcUrl);

            let attempts = 0;
            const maxAttempts = 3;
            while (attempts < maxAttempts) {
                try {
                    const transactionResponse = await provider.getTransaction(
                        transactionHash
                    );

                    if (transactionResponse === null) {
                        attempts++;
                        continue;
                    }

                    const transactionReceipt = await transactionResponse.wait();

                    return Promise.resolve(transactionReceipt);
                } catch (error) {
                    attempts++;
                    if (attempts === maxAttempts) {
                        return Promise.reject();
                    }
                }
            }
        },
        []
    );

    const waitForAllContractsDeployed = useCallback(async () => {
        try {
            await Promise.all(
                pendingTransactionsDetails.map(async (details) => {
                    const result = await waitForContractDeployed(
                        details.networkId,
                        details.transactionHash
                    );
                    if (result) {
                        setContractsTransactionReceipts([
                            ...contractsTransactionReceipts,
                            result,
                        ]);
                    }
                })
            );
        } catch (error) {
            setContractsDeploymentError(true);
        }

        onAllContractsDeployed();
    }, [
        contractsTransactionReceipts,
        onAllContractsDeployed,
        pendingTransactionsDetails,
        waitForContractDeployed,
    ]);

    useEffectOnce(() => {
        waitForAllContractsDeployed();
    });

    if (contractsDeploymentError) {
        return <FailedDataFetchingMessage title="Contracts failed to deploy" />;
    }

    if (getNetworksIsError) {
        return (
            <FailedDataFetchingMessage title="Failed to get networks information" />
        );
    }

    if (getNetworksIsLoading) {
        return <LoadingBox height="10rem" />;
    }

    const contractFormatted =
        networkNames.length === 1 ? "contract" : "contracts";
    const networksFormatted = toSentence(networkNames);

    let icon = <Spinner />;
    let title = (
        <>
            Deploying {contractFormatted} on{" "}
            <strong>{networksFormatted}</strong>
        </>
    );
    let description = (
        <>
            This will take up to 5 min depending on the network. You will only
            have to do this once. Please do not close this page.
        </>
    );
    if (allContractsDeployed) {
        icon = <Checkmark fill={colors.success} height="2rem" width="2rem" />;
        title = (
            <>
                {contractFormatted} deployed on {networksFormatted}
            </>
        );
        description = <></>;
    }

    if (contractsDeploymentError) {
        icon = <Close fill={colors.error} height="1.2rem" width="1.2rem" />;
        title = (
            <>
                Failed to deploy {contractFormatted} on {networksFormatted}
            </>
        );
        description = (
            <>
                Contact{" "}
                <Anchor href={`mailto:${import.meta.env.VITE_EMAIL_SUPPORT}`}>
                    {import.meta.env.VITE_EMAIL_SUPPORT}
                </Anchor>{" "}
                if you continue to experience issues.
            </>
        );
    }

    return (
        <div>
            <S.TitleWrapper>
                {icon}
                <h2>{title}</h2>
            </S.TitleWrapper>
            <p>{description}</p>
        </div>
    );
}

export default forwardRef(SelfServeDeployingContracts);
