import { formatUnits } from "ethers";

// This function pairs well with the `coins` regex to broaden the scope of what is considered a number
export const toNumber = (value: string | number): number => {
    if (typeof value === `string` && value.trim() === `.`) return 0; // Only a decimal point (for onInput computation)
    if (typeof value === `string`) value = value.replace(/,/g, ``); // Remove commas
    return Number(value);
};

export const toNetworkHex = (value: number): string =>
    `0x${value.toString(16)}`;

export const toNetworkId = (value: string | number): number => {
    if (typeof value === `string` && value.startsWith("0x"))
        return parseInt(value, 16);
    else if (typeof value === `string`) return parseInt(value);

    return value;
};

export const ceilNumber = (num: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.ceil(num * factor) / factor;
};

export const floorNumber = (num: number, decimals: number): number => {
    const factor = Math.pow(10, decimals);
    return Math.floor(num * factor) / factor;
};

export const times100 = (input: number | string, decimals: number = 0) => {
    return (
        100 *
        parseFloat(
            typeof input === "string"
                ? input.replace(/[,]/g, "")
                : input.toString()
        )
    ).toFixed(decimals);
};

export const biToStr = (bn: bigint, decimal: number = 6): string =>
    formatUnits(bn, decimal);

export const biToNum = (bn: bigint, decimal: number = 6): number =>
    toNumber(formatUnits(bn, decimal));
