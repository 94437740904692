import * as S from "./style";
import { useState, useEffect, useCallback, ReactNode } from "react";
import { formatUnits } from "ethers";
import { regex, regexToPattern } from "utils/regex";
import { toNumber } from "utils/numbers";
import { useNotificationQueue } from "context/NotificationQueue";
import { useSafeApi } from "hooks/useSafeApi";
import InputEditInline, { State } from "components/InputEditInline";
import { NotificationType } from "components/Notification";
import { useWallet } from "context/Wallet";

interface TokenAllowanceProps {
    token: BaseToken;
    contract: string;
    company?: string;
    icons?: boolean;
}

const TokenAllowance = ({
    token,
    contract,
    company,
    icons = false,
}: TokenAllowanceProps) => {
    const {
        walletConnected,
        networkConnected,
        getTokenAllowance,
        setTokenAllowance,
    } = useWallet();
    const { addNotification, removeNotification } = useNotificationQueue();
    const { sendApproveAllownaceForSafe } = useSafeApi();
    const [allowance, setAllowance] = useState<string | null>(null);
    const [state, setState] = useState<State>();

    const getAllowance = useCallback(async () => {
        if (!networkConnected?.networkId || !walletConnected?.address) {
            setAllowance(null);
            return;
        }

        let tokenAllowance: bigint | false = false;
        try {
            tokenAllowance = await getTokenAllowance({
                tokenAddress: token.address,
                networkId: networkConnected.networkId,
                walletAddress:
                    walletConnected?.proxyFor || walletConnected?.address,
                contractAddress: contract,
                force: true,
            });
        } catch (error) {}

        setAllowance(
            tokenAllowance === false
                ? null
                : String(formatUnits(tokenAllowance, token.decimals))
        );
    }, [
        getTokenAllowance,
        token?.address,
        token?.decimals,
        walletConnected?.proxyFor,
        walletConnected?.address,
        networkConnected?.networkId,
        contract,
    ]);

    const onSubmit = useCallback(
        async (proposedAllowance: string) => {
            // Sanitize input
            proposedAllowance = String(toNumber(proposedAllowance));

            const notificationId: string = addNotification({
                msg: `Updating ${token.name} allowance to ${proposedAllowance}${
                    company ? ` for ${company}'s contract...` : ``
                }`,
                type: NotificationType.WORKING,
                expires: false,
            });

            setAllowance(proposedAllowance);

            (walletConnected?.proxyFor
                ? sendApproveAllownaceForSafe({
                      token: token,
                      contract,
                      amount: proposedAllowance,
                  })
                : setTokenAllowance({
                      contractAddress: contract,
                      tokenAddress: token.address,
                      amount: proposedAllowance,
                      decimals: token.decimals,
                      awaitConfirm: true,
                  })
            )
                .then((result) => {
                    if (result === null)
                        throw new Error(`Your allowance was not increased`);

                    setAllowance(null);
                    getAllowance();
                    addNotification({
                        msg: walletConnected?.proxyFor
                            ? (result as ReactNode)
                            : `${token.name} allowance updated${
                                  company ? ` for ${company}'s contract...` : ``
                              }`,
                        type: NotificationType.SUCCESS,
                    });
                })
                .catch((error: string) => {
                    addNotification({
                        msg: error,
                        type: NotificationType.ERROR,
                    });
                    setAllowance(allowance);
                })
                .finally(() => {
                    removeNotification(notificationId);
                    setState(State.Idle);
                });
        },
        [
            getAllowance,
            setTokenAllowance,
            sendApproveAllownaceForSafe,
            addNotification,
            removeNotification,
            allowance,
            contract,
            token,
            company,
            walletConnected?.proxyFor,
        ]
    );

    const onStateChange = useCallback((newState: State) => {
        setState(newState);
    }, []);

    useEffect(() => {
        if (allowance === null) getAllowance();
    }, [allowance, getAllowance]);

    return (
        <S.TokenAllowance disabled={state === State.Working}>
            <label title={token.name} htmlFor={token.address}>
                {token.symbol}
            </label>
            {!allowance ? (
                <S.LoadingAllowance desaturate />
            ) : (
                <InputEditInline
                    id={token.address}
                    value={allowance || `-`}
                    save={`send tx`}
                    icons={icons}
                    pattern={regexToPattern(regex.coins)}
                    state={state}
                    disabled={!allowance}
                    onSubmit={onSubmit}
                    onStateChange={onStateChange}
                    slim
                />
            )}
        </S.TokenAllowance>
    );
};
export default TokenAllowance;
