import { Contract, Interface, parseUnits } from "ethers";
import { ContractToken } from "admin/types";
import factoryAbi from "abis/abi/LoopFactory.json";
import variableRateTransfersABI from "abis/abi/VariableRateTransfers.json";
import { useTokens } from "hooks/useTokens";
import { useWallet } from "context/Wallet";
import { EvmWalletSigner } from "types/common";

const useVariableRatesContract = () => {
    const { walletConnected, networkConnected } = useWallet();
    const { getTokenOnNetwork }: any = useTokens();

    const createContract = async (
        baseFee: number,
        variableFee: number,
        acceptedTokens: ContractToken[],
        inboundTreasury: string, // Address
        outboundTreasury: string, // Address
        signer: string, // Address
        governor: string, // Address
        securityLevel: number, // enum
        factoryAddr: string // Address
    ) => {
        const acceptedTokenAddresses = acceptedTokens.map(
            (token: ContractToken) => token.address
        );
        const chainlinkAggregators = acceptedTokens.map(
            (token: ContractToken) => token.aggregatorAddress
        );

        const wETH = getTokenOnNetwork(networkConnected?.networkId, `WETH`);

        const factory = new Contract(
            factoryAddr,
            factoryAbi,
            walletConnected?.signer as EvmWalletSigner
        );

        // declare vr contract interface
        const vrTransfersInterface = new Interface(variableRateTransfersABI);

        // encode initialize() function data
        const initializeCalldata = vrTransfersInterface.encodeFunctionData(
            `initialize`,
            [
                [
                    parseUnits(baseFee.toString(), 2), // convert $ value to cents -> $0.15 should become 15
                    parseUnits(variableFee.toString(), 4), // convert % value correct decimals for the contract -> 10% should become 100000
                    40,
                ],
                [acceptedTokenAddresses, chainlinkAggregators],
                [inboundTreasury, outboundTreasury, signer],
                securityLevel,
                [factoryAddr, wETH.tokenAddress],
                governor,
            ]
        );

        let tsx;
        try {
            tsx = await factory.createProxy(2, initializeCalldata);
        } catch (errMsg) {
            throw new Error(`Transaction failed at createProxy(): ${errMsg}`);
        }

        let receipt;
        try {
            receipt = await tsx.wait();
        } catch (errMsg) {
            throw new Error(
                `Transaction failed waiting for the transaction: ${errMsg}`
            );
        }

        const newContractDeployedEvent = receipt.events.find(
            (event: any) => event.event === `NewProxyDeployed`
        );

        const newContractAddr =
            newContractDeployedEvent.args.newContractAddress;

        return [receipt, newContractAddr];
    };

    return { createContract };
};

export default useVariableRatesContract;
