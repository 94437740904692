import { AvailableNetwork } from "default-variables";
import { useCallback, useState } from "react";
import { parseUnits } from "ethers";
import { PrimaryWalletAccount } from "context/Wallet/hooks/useWalletConnected";
import {
    getEvmTokenAllowance,
    getSolTokenAllowance,
    setEvmTokenAllowance,
    setSolTokenAllowance,
} from "utils/tokens";
import { EvmProvider, SolProvider } from "types/common";
import { strNumToDecimalPrecision } from "utils/financial";
import { useChainProvider } from "hooks/useChainProvider";

export interface WalletTokenAllowance {
    networkId: string; // hex
    wallet: string;
    token: string;
    contract: string;
    allowance: bigint;
    updatedAt: number;
}

export interface TokenAllowanceProps {
    tokenAddress: string;
    contractAddress: string;
    walletAddress?: string;
    networkId?: string;
}

export interface GetTokenAllowanceProps extends TokenAllowanceProps {
    force?: boolean;
}

export interface SetTokenAllowanceProps {
    contractAddress: string;
    tokenAddress: string;
    awaitConfirm?: boolean;
    amount: string | number | bigint;
    decimals: number;
}

const allowanceExpiryLimit = 3 * 60 * 1000; // 3 minutes

const useWalletAllowance = (
    connectedWallet: PrimaryWalletAccount | null,
    connectedNetwork: AvailableNetwork | null
) => {
    const [allowances, setAllowances] = useState<WalletTokenAllowance[]>([]);
    const { getProviderForAnyWalletAndNetwork } = useChainProvider(
        connectedWallet,
        connectedNetwork
    );

    const getStoredTokenAllowanceIndex = useCallback(
        ({
            tokenAddress,
            contractAddress,
            walletAddress,
            networkId,
        }: TokenAllowanceProps): {
            existingIndex: number;
            wallet: string;
            network: AvailableNetwork;
        } => {
            const { wallet, network } = getProviderForAnyWalletAndNetwork({
                walletAddress,
                networkId,
            });

            // Use the wallet's address to represent the native token address
            const tokenAddressForStorage = tokenAddress || wallet;

            const existingIndex = allowances.findIndex(
                (existing) =>
                    existing.token === tokenAddressForStorage &&
                    existing.contract === contractAddress &&
                    existing.wallet === wallet &&
                    existing.networkId === network.networkId
            );

            return { existingIndex, wallet, network };
        },
        [allowances, getProviderForAnyWalletAndNetwork]
    );

    const fetchTokenAllowance = useCallback(
        async ({
            // force = false, // For the delay timer
            tokenAddress,
            contractAddress,
            walletAddress,
            networkId,
        }: TokenAllowanceProps): Promise<bigint> => {
            try {
                const { chainAndProvider, wallet } =
                    getProviderForAnyWalletAndNetwork({
                        walletAddress,
                        networkId,
                    });

                // Use the wallet's address to represent the native token address
                const tokenAddressForStorage = tokenAddress || wallet;

                let allowance;
                if (chainAndProvider.chain === "SOL") {
                    allowance = await getSolTokenAllowance({
                        provider: chainAndProvider.provider as SolProvider,
                        walletAddress: wallet,
                        contractAddress,
                        tokenAddress: tokenAddressForStorage,
                    });
                } else {
                    allowance = await getEvmTokenAllowance({
                        provider: chainAndProvider.provider as EvmProvider,
                        walletAddress: wallet,
                        contractAddress,
                        tokenAddress: tokenAddressForStorage,
                    });
                }

                return allowance;
            } catch (error) {
                console.error(`Failed to get token allowance: ${error}`);
                return BigInt(0);
            }
        },
        [getProviderForAnyWalletAndNetwork]
    );

    const hasTokenAllowanceStored = useCallback(
        ({
            tokenAddress,
            contractAddress,
            walletAddress,
            networkId,
        }: TokenAllowanceProps): boolean => {
            const { existingIndex } = getStoredTokenAllowanceIndex({
                tokenAddress,
                contractAddress,
                walletAddress,
                networkId,
            });

            return existingIndex !== -1;
        },
        [getStoredTokenAllowanceIndex]
    );

    const getTokenAllowance = useCallback(
        async ({
            tokenAddress,
            contractAddress,
            walletAddress,
            networkId,
            force = false,
        }: GetTokenAllowanceProps): Promise<bigint> => {
            const { existingIndex, wallet, network } =
                getStoredTokenAllowanceIndex({
                    tokenAddress,
                    contractAddress,
                    walletAddress,
                    networkId,
                });

            // An allowance exists for this network-wallet-token-contract and is not expired (and not being forced to refresh)
            if (
                existingIndex !== -1 &&
                allowances[existingIndex].updatedAt + allowanceExpiryLimit >
                    Date.now() &&
                !force
            ) {
                return allowances[existingIndex].allowance;
            }

            const allowance = await fetchTokenAllowance({
                tokenAddress,
                contractAddress,
                walletAddress,
                networkId,
            });

            setAllowances((prevAllowances) => {
                if (existingIndex !== -1) {
                    // An allowance already exists for this network-wallet-token-contract, update it if not identical
                    if (prevAllowances[existingIndex].allowance !== allowance) {
                        const updatedAllowances = [...prevAllowances];
                        updatedAllowances[existingIndex] = {
                            ...updatedAllowances[existingIndex],
                            allowance,
                            updatedAt: Date.now(),
                        };
                        return updatedAllowances;
                    }

                    return prevAllowances;
                } else {
                    // Use the wallet's address to represent the native token address
                    const tokenAddressForStorage = tokenAddress || wallet;

                    // Add a new allowance record
                    return [
                        ...prevAllowances,
                        {
                            networkId: network.networkId,
                            wallet,
                            token: tokenAddressForStorage,
                            contract: contractAddress,
                            allowance,
                            updatedAt: Date.now(),
                        },
                    ];
                }
            });

            return allowance;
        },
        [allowances, fetchTokenAllowance, getStoredTokenAllowanceIndex]
    );

    const setTokenAllowance = useCallback(
        async ({
            contractAddress,
            tokenAddress,
            amount,
            decimals,
            awaitConfirm = false, // confirm tx amount before return
        }: SetTokenAllowanceProps) => {
            try {
                if (!connectedWallet) {
                    throw new Error(`Wallet is not connected`);
                }

                const allowanceAmount =
                    typeof amount === "bigint"
                        ? amount
                        : parseUnits(
                              strNumToDecimalPrecision(
                                  String(amount),
                                  decimals
                              ),
                              decimals
                          );

                let allowance: bigint;
                if (connectedWallet.chain === "SOL") {
                    allowance = await setSolTokenAllowance({
                        provider: connectedWallet.provider,
                        signer: connectedWallet.signer,
                        walletAddress: connectedWallet.address,
                        contractAddress,
                        tokenAddress,
                        amount: allowanceAmount,
                        decimals: decimals,
                        awaitConfirm: awaitConfirm,
                    });
                } else {
                    allowance = await setEvmTokenAllowance({
                        signer: connectedWallet.signer,
                        contractAddress,
                        tokenAddress,
                        amount: allowanceAmount,
                        awaitConfirm: awaitConfirm,
                    });
                }

                return allowance;
            } catch (error) {
                console.error(`Failed to set token allowance: ${error}`);
                return null;
            }
        },
        [connectedWallet]
    );

    const addToTokenAllowance = useCallback(
        async ({
            contractAddress,
            tokenAddress,
            amount,
            decimals,
            awaitConfirm = false, // confirm tx amount before return
        }: SetTokenAllowanceProps): Promise<bigint | null> => {
            const existingAllowance = await getTokenAllowance({
                tokenAddress,
                contractAddress,
            });

            const allowanceAmount =
                typeof amount === "bigint"
                    ? amount
                    : parseUnits(
                          strNumToDecimalPrecision(String(amount), decimals),
                          decimals
                      );

            const allowance = await setTokenAllowance({
                contractAddress,
                tokenAddress,
                amount: existingAllowance + allowanceAmount,
                decimals,
                awaitConfirm,
            });

            return allowance;
        },
        [getTokenAllowance, setTokenAllowance]
    );

    const setTokenAllowanceIfInsufficient = useCallback(
        async ({
            contractAddress,
            tokenAddress,
            amount,
            decimals,
            awaitConfirm = false, // confirm tx amount before return
        }: SetTokenAllowanceProps): Promise<bigint | false | null> => {
            const allowanceAmount =
                typeof amount === "bigint"
                    ? amount
                    : parseUnits(
                          strNumToDecimalPrecision(String(amount), decimals),
                          decimals
                      );

            const existingAllowance = BigInt(
                await getTokenAllowance({
                    tokenAddress,
                    contractAddress,
                })
            );

            if (allowanceAmount < existingAllowance) return false;

            const allowance = await setTokenAllowance({
                contractAddress,
                tokenAddress,
                amount,
                decimals,
                awaitConfirm,
            });

            return allowance;
        },
        [getTokenAllowance, setTokenAllowance]
    );

    return {
        hasTokenAllowanceStored,
        getTokenAllowance,
        setTokenAllowance,
        addToTokenAllowance,
        setTokenAllowanceIfInsufficient,
    };
};

export default useWalletAllowance;
