import * as S from "./style";
import { memo } from "react";
import { firstToUpper } from "utils/strings";
import { Customer } from "customer/types";
import { useNativeTokens, WrapperToken } from "hooks/useNativeTokens";
import Anchor from "components/Anchor";
import TokenAllowance from "components/TokenAllowance";
import TokenWrappable from "customer/components/TokenWrappable";
import Tooltip from "components/Tooltip";
import Info from "components/icons/Info";

export interface AuthorizationItem {
    id: string;
    name: string;
    active: boolean;
}

export interface AuthorizedEntity {
    entityId: string;
    name: string;
    websiteUrl?: string | null;
    logoUrl?: string | null;
    tokens: Customer.Token[];
    items: AuthorizationItem[];
    contract: Customer.Contract;
}

interface AuthorizationProps {
    data: AuthorizedEntity;
}

const Authorization = memo(({ data }: AuthorizationProps) => {
    const { wrappableTokens } = useNativeTokens(data.tokens);

    return (
        <S.Authorization forwardedAs="li">
            <S.Entity>
                {data.logoUrl && (
                    <S.Logo>
                        <S.NameOrLogo
                            forwardedAs="div"
                            entity={{
                                name: data.name,
                                logo: data.logoUrl,
                                url: data.websiteUrl,
                            }}
                            short={true}
                        />
                    </S.Logo>
                )}
                <h2>{data.name}</h2>
                {data.websiteUrl && (
                    <Anchor
                        href={data.websiteUrl}
                        icon
                        underlined={false}
                        block
                        nowrap
                    >
                        {data.websiteUrl}
                    </Anchor>
                )}
            </S.Entity>
            <S.Allowances>
                <S.HeadingWithTip>
                    <h3>Allowances</h3>
                    <Tooltip
                        title={
                            <>
                                <strong>No funds are being moved</strong> - you
                                are simply setting the most that{" "}
                                <em>{data.name}</em> can interact with your
                                wallet. With each autopay this amount declines
                                by the invoice amount, thus this may need to be
                                increased in the future to ensure the invoice is
                                processed.
                                <br />
                                <Anchor
                                    href={
                                        import.meta.env
                                            .VITE_LOOP_DOCS_ALLOWANCE_FUNCTION
                                    }
                                >
                                    Learn more about the allowance function
                                </Anchor>
                            </>
                        }
                        placement="right"
                    >
                        <S.TipIcon>
                            <Info height="1rem" width="1rem" />
                        </S.TipIcon>
                    </Tooltip>
                </S.HeadingWithTip>
                <S.CoinList>
                    {data.tokens.map((token: Customer.Token) => {
                        return (
                            <li key={token.symbol}>
                                <TokenAllowance
                                    token={token}
                                    contract={data.contract.address}
                                    company={data.name}
                                />
                            </li>
                        );
                    })}
                </S.CoinList>
            </S.Allowances>
            {wrappableTokens.length > 0 && (
                <S.Wrappables>
                    <S.HeadingWithTip>
                        <h3>Available to wrap</h3>
                        <Tooltip
                            title={
                                <>
                                    Quickly and easily wrap some, or all, of
                                    your native tokens's balance (shown beside
                                    each symbol).
                                    <ol>
                                        <li>
                                            Click "wrap to <em>[symbol]</em>"
                                            beside your token's balance.
                                        </li>
                                        <li>
                                            Edit the amount you want to wrap
                                        </li>
                                        <li>Click "wrap"</li>
                                        <li>
                                            Check your wallet for instructions
                                        </li>
                                    </ol>
                                    <strong>
                                        No funds will be wrapped without your
                                        confirmation.
                                    </strong>
                                    <br />
                                    <Anchor
                                        href={
                                            import.meta.env
                                                .VITE_LOOP_DOCS_WRAP_NATIVE
                                        }
                                    >
                                        Learn more about wrapping native tokens
                                    </Anchor>
                                </>
                            }
                            placement="right"
                        >
                            <S.TipIcon>
                                <Info height="1rem" width="1rem" />
                            </S.TipIcon>
                        </Tooltip>
                    </S.HeadingWithTip>
                    <S.CoinList>
                        {wrappableTokens.map((token: WrapperToken) => {
                            return (
                                <li key={token.symbol}>
                                    <TokenWrappable token={token} />
                                </li>
                            );
                        })}
                    </S.CoinList>
                </S.Wrappables>
            )}
            <S.Items>
                <div>
                    <h3>Active authorizations</h3>
                    <ul>
                        {data.items.map(({ id, name, active }) => (
                            <li key={id}>
                                {active ? (
                                    firstToUpper(name)
                                ) : (
                                    <Tooltip
                                        title="Canceled or scheduled to be canceled"
                                        slim
                                    >
                                        <S.Cancelled>
                                            {firstToUpper(name)}
                                        </S.Cancelled>
                                    </Tooltip>
                                )}
                            </li>
                        ))}
                    </ul>
                </div>
                <S.Manage href={`/authorizations/details/${data.entityId}`}>
                    Manage
                </S.Manage>
            </S.Items>
        </S.Authorization>
    );
});

export default Authorization;
