import { FixedNumber, formatUnits, parseUnits } from "ethers";
import { biToStr, times100, toNumber } from "./numbers";
import { convertTokenToCents } from "./exchangeRates";

export const toCents = (dollars: any) => {
    return Math.round(toNumber(times100(dollars, 0)));
};

export const formatCurrency = (cents: any, options?: any) => {
    return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        ...options,
    }).format(cents / 100);
};

export const toDollar = (cents: any, roundTo = 2) => {
    // Allowing international formats to be displayed regionally, rather than forcing US standards
    return `$${new Intl.NumberFormat(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: roundTo,
    }).format(Number(cents) / 100)}`;
};

export enum Round {
    ROUND = "halfCeil",
    CEIL = "ceil",
    FLOOR = "floor",
}

export const toCoin = (
    amount: number | string,
    roundTo = 6,
    roundMode: Round | "floor" | "ceil" = Round.ROUND
) => {
    const num: string = toFixed(amount);

    if (isNaN(toNumber(num)))
        throw new Error(
            `The first argument (amount) must be a Number, or be able to cast to a Number.`
        );

    // @ts-ignore
    if (num >= 1 || num <= -1 || num === "0") {
        return new Intl.NumberFormat(undefined, {
            maximumFractionDigits: roundTo,
            roundingMode: roundMode,
        }).format(toNumber(num));
    }

    // All fractional digits
    const fromDecimal = String(num).slice(String(num).indexOf(`.`) + 1);

    // Truncate to the specified number of digits
    let fraction = fromDecimal
        .match(new RegExp(`^(0*\\d{0,${roundTo}})`))
        ?.at(1);

    // Check if rounding is necessary, only if any digits were cropped off
    if (fraction && fromDecimal.length > fraction.length) {
        // Split the fraction into leading zeros and significant digits
        const [leadingZeros, significantDigits] = ((match) =>
            match ? [match[1], match[2]] : [``, fraction])(
            fraction.match(/^(0*)(\d+)$/)
        );

        // No rounding specified, use the standard rules, OR, round up if any remaining significant digits
        if (
            (!roundMode && Number(fromDecimal.at(fraction.length)) >= 5) ||
            (roundMode === Round.CEIL &&
                Number(fromDecimal.slice(fraction.length)) > 0)
        ) {
            fraction = leadingZeros + (+significantDigits + 1);
        }
    }

    return String(num)
        .slice(0, String(num).indexOf(`.`))
        .concat(`.`, fraction || ``)
        .replace(/(\.\d*?[1-9])0+$|\.0*$/, "$1");
};

export const bnToCoin = (
    bn: bigint,
    decimals = 6,
    roundTo = decimals,
    roundMode: Round = Round.ROUND
) => {
    return toCoin(biToStr(bn, decimals), roundTo, roundMode);
};

export const getBigIntFromDecimal = (tokenAmt: string, decimals = 18) => {
    if (typeof tokenAmt != `string`) {
        // Converting to String from a long decimal creates a loss of precision. Eg,
        //      String(1111.012345678901234567) -> `1111.0123456789013`
        console.error(`The value to convert must be a String`);
    }

    const decimalPos = tokenAmt.indexOf(`.`) + 1;
    if (!decimalPos) {
        // No decimal was found
        return parseUnits(tokenAmt, decimals);
    }

    return parseUnits(tokenAmt.slice(0, decimalPos + decimals), decimals);
};

export const strNumToDecimalPrecision = (strNum: string, decimals = 18) => {
    const decimalPos = strNum.indexOf(`.`) + 1;
    return decimalPos ? strNum.slice(0, decimalPos + decimals) : strNum;
};

// Can take a number or a string, just note possible precision loss through toFixed()
export const undecimalizeNumber = (num: any, decimals = 18) =>
    parseUnits(
        strNumToDecimalPrecision(toFixed(num), decimals),
        decimals
    ).toString();

// From https://gist.github.com/jiggzson/b5f489af9ad931e3d186
// Modified to always return a string
export const toFixed = (num: any) => {
    var nsign = Math.sign(num);
    //remove the sign
    num = Math.abs(num);

    if (num > Number.MAX_SAFE_INTEGER) {
        console.warn(
            `The number ${num} is too large to safely convert and may lose precision.`
        );
    }

    //if the number is in scientific notation remove it
    if (/\d+\.?\d*e[+-]*\d+/i.test(num)) {
        var zero = "0",
            parts = String(num).toLowerCase().split("e"), //split into coeff and exponent
            e = parts.pop(), //store the exponential part
            // @ts-ignore
            l = Math.abs(e), //get the number of zeros
            // @ts-ignore
            sign = e / l,
            coeff_array = parts[0].split(".");
        if (sign === -1) {
            l = l - coeff_array[0].length;
            if (l < 0) {
                num =
                    coeff_array[0].slice(0, l) +
                    "." +
                    coeff_array[0].slice(l) +
                    (coeff_array.length === 2 ? coeff_array[1] : "");
            } else {
                num =
                    zero +
                    "." +
                    new Array(l + 1).join(zero) +
                    coeff_array.join("");
            }
        } else {
            var dec = coeff_array[1];
            if (dec) l = l - dec.length;
            if (l < 0) {
                num = coeff_array[0] + dec.slice(0, l) + "." + dec.slice(l);
            } else {
                num = coeff_array.join("") + new Array(l + 1).join(zero);
            }
        }
    }
    const value = nsign < 0 ? "-" + num : num;
    return String(value);
};

export function formatUnitsWithPrecision(
    value: bigint,
    decimals: number = 18,
    maxDecimalDigits: number = decimals
) {
    let formattedValue = FixedNumber.fromString(formatUnits(value, decimals))
        .round(18)
        .toString();

    let [integer, fraction] = formattedValue.split(".");

    // Fully pad the string with zeros to get to wei
    while (fraction.length < maxDecimalDigits) {
        fraction += "0";
    }

    return `${integer}.${fraction.slice(0, maxDecimalDigits)}`;
}

export const formatTokenAndUsd = ({
    amount,
    symbol,
    usdValue,
    round,
    hideUsd = false,
}: any) => {
    return `${toCoin(amount, 6, round)} ${symbol}${
        hideUsd
            ? ``
            : ` (${
                  usdValue
                      ? toDollar(convertTokenToCents(amount, usdValue))
                      : ``
              })`
    }`;
};
