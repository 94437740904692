import { useQueryClient } from "@tanstack/react-query";
import { useWallet } from "context/Wallet";
import { addDays } from "date-fns";
import {
    ReactNode,
    createContext,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { booleanFromString } from "utils/booleans";
import { getCookie, setCookie } from "utils/storage";

export enum LoginType {
    WEB2 = "Web2",
    WEB3 = "Web3",
}

export enum UserRole {
    GUEST = "guest",
    ADMIN = "admin",
    COMPANY = "company",
    COMPANY_READ = "company_read",
}

export enum UserTier {
    GUEST = 1,
    BASIC,
    PREMIUM,
}

interface UserProps {
    children: ReactNode;
    loginType: LoginType;
}

interface UserState {
    token: string | null;
    entityId: string | null;
    roles: UserRole[];
    tier: UserTier;
    companyName: string | null;
    email: string | null;
    delegatedSigning: boolean | null;
}

export type HasRoleFunction = (rolesToCheck: UserRole[] | UserRole) => boolean;

interface UserContextValue {
    userReady: boolean;
    newUserSession: (userData: UserState, expiry?: Date) => void;
    hasRole: HasRoleFunction;
    isTier: (tiersToCheck: UserTier[] | UserTier) => boolean;
    isAtLeastTier: (minTier: UserTier) => boolean;
    getSessionToken: () => string;
    getEntityId: () => string;
    getCompanyName: () => string;
    getEmail: () => string;
    getDelegatedSigning: () => boolean;
    logout: () => void;
}

const loggedOutUser: UserState = {
    token: null,
    entityId: null,
    roles: [UserRole.GUEST],
    tier: UserTier.GUEST,
    companyName: null,
    email: null,
    delegatedSigning: null,
};

const UserContext = createContext<UserContextValue | undefined>(undefined);

const UserProvider = ({ children, loginType }: UserProps) => {
    const [userState, setUserState] = useState<UserState>(loggedOutUser);
    const [expiration, setExpiration] = useState<Date>(addDays(new Date(), 1));
    const [userReady, setUserReady] = useState<boolean>(false);
    const queryClient = useQueryClient();
    const { requiresDynamicLogin, isDynamicLoggedIn, handleWalletLogout } =
        useWallet();

    useEffect(() => {
        // Initialize user state from storage here
        const storedState = {
            token: getCookie(`token`) || loggedOutUser.token,
            entityId: getCookie(`entityId`) || loggedOutUser.entityId,
            roles: getCookie("roles")
                ? (getCookie("roles").split(",") as UserRole[])
                : loggedOutUser.roles,
            tier: getCookie("tier")
                ? tierStrToEnum(getCookie("tier"))
                : loggedOutUser.tier,
            companyName: getCookie("companyName") || loggedOutUser.companyName,
            email: getCookie("email") || loggedOutUser.email,
            delegatedSigning: getCookie("delegatedSigning")
                ? booleanFromString(getCookie("delegatedSigning"))
                : loggedOutUser.delegatedSigning,
        };

        setUserState(storedState);
        setUserReady(true);
    }, []);

    useEffect(() => {
        if (!userReady) return;

        const cookieData = [
            { key: `token`, value: userState.token || ``, expiration },
            { key: `entityId`, value: userState.entityId || ``, expiration },
            { key: `roles`, value: userState.roles.join(`,`), expiration },
            { key: `tier`, value: String(userState.tier) || ``, expiration },
            {
                key: `companyName`,
                value: userState.companyName || ``,
                expiration,
            },
            { key: `email`, value: userState.email || ``, expiration },
            {
                key: `delegatedSigning`,
                value: userState.delegatedSigning?.toString() ?? ``,
                expiration,
            },
        ];

        setCookie(cookieData);
    }, [userState, userReady, expiration]);

    const hasRole = useCallback(
        (rolesToCheck: UserRole[] | UserRole): boolean => {
            if (!Array.isArray(rolesToCheck)) rolesToCheck = [rolesToCheck];

            return rolesToCheck.some((role) => userState.roles!.includes(role));
        },
        [userState.roles]
    );

    const isTier = useCallback(
        (tiersToCheck: UserTier[] | UserTier): boolean => {
            if (!Array.isArray(tiersToCheck)) tiersToCheck = [tiersToCheck];

            return tiersToCheck.includes(userState.tier);
        },
        [userState.tier]
    );

    const isAtLeastTier = useCallback(
        (minTier: UserTier): boolean => {
            return userState.tier >= minTier;
        },
        [userState.tier]
    );

    const getEntityId = useCallback(() => {
        return userState.entityId || ``;
    }, [userState.entityId]);

    const getSessionToken = useCallback(() => {
        let tokenValue = userState.token || ``;

        if (tokenValue && loginType === LoginType.WEB2)
            tokenValue = `Bearer ${tokenValue}`;

        return tokenValue;
    }, [userState.token, loginType]);

    const getCompanyName = useCallback(() => {
        return userState.companyName || ``;
    }, [userState.companyName]);

    const getDelegatedSigning = useCallback(() => {
        return userState.delegatedSigning || false;
    }, [userState.delegatedSigning]);

    const getEmail = useCallback(() => {
        return userState.email || ``;
    }, [userState.email]);

    const logout = useCallback(() => {
        queryClient.clear();
        handleWalletLogout();
        setUserState(loggedOutUser);
    }, [queryClient, handleWalletLogout]);

    useEffect(() => {
        if (!isDynamicLoggedIn && requiresDynamicLogin) {
            logout();
        }
    }, [isDynamicLoggedIn, requiresDynamicLogin, logout]);

    const value = useMemo<UserContextValue>(
        () => ({
            userReady,
            newUserSession: (
                userData: UserState,
                expiry: Date = addDays(new Date(), 1)
            ) => {
                // Filer out bad roles
                userData.roles = userData.roles.filter((role) =>
                    Object.values(UserRole).includes(role as UserRole)
                );

                // Set default roles and tier if not available
                if (!userData.roles.length)
                    userData.roles = loggedOutUser.roles;
                if (
                    !Object.values(UserTier).includes(userData.tier as UserTier)
                )
                    userData.tier = loggedOutUser.tier;

                setUserState(userData);
                setExpiration(expiry);
            },
            hasRole,
            isTier,
            isAtLeastTier,
            getEntityId,
            getSessionToken,
            getCompanyName,
            getDelegatedSigning,
            getEmail,
            logout,
        }),
        [
            userReady,
            hasRole,
            isTier,
            isAtLeastTier,
            getEntityId,
            getSessionToken,
            getCompanyName,
            getDelegatedSigning,
            getEmail,
            logout,
        ]
    );

    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};

const useUser = (): UserContextValue & { customer?: boolean } => {
    const context = useContext(UserContext);
    if (context === undefined) {
        // Go back to the "throw" once SessionProvider is removed:
        // throw new Error(`useUser() must be used within a UserProvider`);

        return {
            userReady: true,
            newUserSession: () => null,
            hasRole: () => false,
            isTier: () => false,
            isAtLeastTier: () => false,
            getSessionToken: () => ``,
            getEntityId: () => ``,
            getCompanyName: () => ``,
            getEmail: () => ``,
            getDelegatedSigning: () => false,
            logout: () => null,
            customer: true,
        };
    }
    return context;
};

export const tierStrToEnum = (tierStr: string): UserTier => {
    switch (tierStr.toLowerCase()) {
        case "guest":
            return UserTier.GUEST;
        case "basic":
            return UserTier.BASIC;
        case "premium":
            return UserTier.PREMIUM;
        default:
            return UserTier.GUEST;
    }
};

export { UserProvider, useUser };
