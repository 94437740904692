const ratePrecision = 100; // backend format to 4 decimals

export const convertTokenToCents = (
    tokenAmount: number,
    rate: number
): number => (tokenAmount * rate) / ratePrecision;

export const convertCentsToToken = (
    amountInCents: number,
    rate: number
): number => (amountInCents * ratePrecision) / rate;

export const convertCentsToTokenBN = (
    amountInCents: bigint,
    rate: number
): bigint => (amountInCents * BigInt(ratePrecision)) / BigInt(rate);
