import {
    createApproveCheckedInstruction,
    getAccount,
    getAssociatedTokenAddress,
} from "@solana/spl-token";
import {
    ComputeBudgetProgram,
    PublicKey,
    Transaction,
    TransactionSignature,
} from "@solana/web3.js";
import { tokenABI } from "default-variables";
import { Contract, formatUnits } from "ethers";
import {
    EvmProvider,
    EvmWalletSigner,
    SolProvider,
    SolWalletSigner,
} from "types/common";
import { confirmTransactionWithRetries } from "./solanan";

export const getEvmTokenBalance = async ({
    provider,
    walletAddress,
    tokenAddress,
}: {
    provider: EvmProvider;
    walletAddress: string;
    tokenAddress?: string | null;
}): Promise<number> => {
    let decimals, balance;
    try {
        if (tokenAddress) {
            const erc20 = new Contract(tokenAddress, tokenABI, provider);

            decimals = await erc20.decimals();
            balance = await erc20.balanceOf(walletAddress);
        } else {
            // Native token
            balance = await provider.getBalance(walletAddress);
            decimals = 18;
        }
    } catch (error) {
        // [ ] This should probably throw, rather than log and leave the catch up to the caller
        console.error(`Failed to get EVM balance: ${error}`);
        return 0;
    }

    // [ ] Maybe we need to consider just returning a BigInt here and adjusting that in the wallet
    return Number(formatUnits(balance, decimals));
};

export const getSolTokenBalance = async ({
    provider,
    walletAddress,
    tokenAddress,
}: {
    provider: SolProvider;
    walletAddress: string;
    tokenAddress?: string | null; // mint
}): Promise<number> => {
    let decimals, balance;
    try {
        if (tokenAddress) {
            const tokenAccount = await getAssociatedTokenAddress(
                new PublicKey(tokenAddress),
                new PublicKey(walletAddress)
            );

            const tokenAccountBalance = await provider.getTokenAccountBalance(
                tokenAccount
            );

            balance = Number(tokenAccountBalance.value.amount);
            decimals = tokenAccountBalance.value.decimals;
        } else {
            // Native token
            balance = await provider.getBalance(new PublicKey(walletAddress));
            decimals = 9;
        }
    } catch (error) {
        // [ ] This should probably throw, rather than log and leave the catch up to the caller
        console.error(`Failed to get Solana balance: ${error}`);
        return 0;
    }

    // [ ] Maybe we need to consider just returning a BigInt here and adjusting that in the wallet
    return Number(formatUnits(balance, decimals));
};

export const getEvmTokenAllowance = async ({
    provider,
    walletAddress,
    contractAddress,
    tokenAddress,
}: {
    provider: EvmProvider;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string;
}): Promise<bigint> => {
    const erc20 = new Contract(tokenAddress, tokenABI, provider);
    return await erc20.allowance(walletAddress, contractAddress);
};

export const getSolTokenAllowance = async ({
    provider,
    walletAddress,
    contractAddress,
    tokenAddress,
}: {
    provider: SolProvider;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string; // mint
}): Promise<bigint> => {
    const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(tokenAddress),
        new PublicKey(walletAddress)
    );

    const { delegate, delegatedAmount } = await getAccount(
        provider,
        tokenAccount
    );

    if (delegate?.toString() !== contractAddress) {
        throw new Error("Delegate does not match contract address");
    }

    return BigInt(delegatedAmount.toString());
};

type SetEvmTokenAllowanceParams = {
    signer: EvmWalletSigner;
    contractAddress: string;
    tokenAddress: string;
    amount: bigint;
    awaitConfirm?: boolean;
};

type SetSolTokenAllowanceParams = {
    provider: SolProvider;
    signer: SolWalletSigner;
    walletAddress: string;
    contractAddress: string;
    tokenAddress: string; // mint
    amount: bigint;
    decimals: number;
    awaitConfirm?: boolean;
};

export const setEvmTokenAllowance = async ({
    signer,
    contractAddress,
    tokenAddress,
    amount,
    awaitConfirm,
}: SetEvmTokenAllowanceParams): Promise<bigint> => {
    const erc20 = new Contract(tokenAddress, tokenABI, signer);

    const allowanceTx = await erc20.approve(contractAddress, amount);

    if (awaitConfirm) {
        const receipt = await allowanceTx.wait();

        if (receipt.status === 0)
            return Promise.reject(
                `There was a problem registering your allowance increase`
            );
    }

    // [ ] Returning the receipt/signature may be useful

    // Note this simply returns the value passed in as the amount, but has not verified it through a requst
    return Promise.resolve(amount);
};

export const setSolTokenAllowance = async ({
    provider,
    signer,
    walletAddress,
    contractAddress,
    tokenAddress,
    amount,
    decimals,
    awaitConfirm,
}: SetSolTokenAllowanceParams): Promise<bigint> => {
    const tokenAccount = await getAssociatedTokenAddress(
        new PublicKey(tokenAddress),
        new PublicKey(walletAddress)
    );

    const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: 1,
    });

    const transaction = new Transaction().add(addPriorityFee).add(
        createApproveCheckedInstruction(
            tokenAccount, // token account
            new PublicKey(tokenAddress), // mint
            new PublicKey(contractAddress), // delegate
            new PublicKey(walletAddress), // owner of token account
            amount,
            decimals
        )
    );

    const blockhash = await provider.getLatestBlockhash();
    transaction.recentBlockhash = blockhash.blockhash;
    transaction.feePayer = new PublicKey(walletAddress);

    const { signature }: { signature: TransactionSignature } =
        await signer.signAndSendTransaction(transaction);

    if (awaitConfirm) {
        try {
            await confirmTransactionWithRetries(
                provider,
                signature,
                "confirmed",
                [10000, 3000]
            );
        } catch (error) {
            // Confirm the allowance was correctly set
            const storedAllowance = await getSolTokenAllowance({
                provider,
                walletAddress,
                contractAddress,
                tokenAddress,
            });

            if (storedAllowance !== amount) {
                throw new Error(
                    `Your allowance (currently set to ${storedAllowance.toString()}) was not confirmed and may not be updated to ${amount.toString()}`
                );
            }
            console.warn(
                `Allowance was unable to verify the allowance using the transaction signature, but did verify the amount manually`
            );
        }
    }

    // [ ] Returning signature/receipt may be useful

    // Note this simply returns the value passed in as the amount, but has not verified it through a requst
    return Promise.resolve(amount);
};
