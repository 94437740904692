import { useCallback, useEffect, useState } from "react";
import {
    useDynamicContext,
    useWalletConnectorEvent,
} from "@dynamic-labs/sdk-react-core";
import { AvailableNetwork } from "default-variables";
import { useAvailableNetworks } from "hooks/useAvailableNetworks";
import { toNetworkId } from "utils/numbers";
import { clusterToNetworkId } from "utils/solanan";

export type SetConnectedNetworkProps = { networkId: number | string };

const chainToNetwork = (networkId: string | number): `0x${string}` | null => {
    if (typeof networkId === `number`)
        return `0x${Number(networkId).toString(16)}`;

    const solanaNetwork = clusterToNetworkId(networkId);
    if (solanaNetwork) return solanaNetwork;

    if (networkId.startsWith(`0x`)) return networkId as `0x${string}`;

    return null;
};

const useWalletNetwork = () => {
    const [network, setNetwork] = useState<AvailableNetwork | null>(null);
    const [isNetworkSetting, setIsNetworkSetting] = useState(false);

    const { primaryWallet } = useDynamicContext();
    const { getNetworkById } = useAvailableNetworks();

    // Process change of network data
    const handleNetworkChange = useCallback(
        (networkId: number | string | undefined) => {
            // Don't assign a new object if the network is the same
            if (Number(networkId) === network?.id) return;
            if (!networkId) return setNetwork(null);

            const id = chainToNetwork(networkId);

            const newNetwork = id && getNetworkById(id);
            setNetwork(newNetwork || null);
        },
        [network?.id, getNetworkById]
    );

    // Used to trigger a network change
    // [ ] Update this to reject with a message
    const setConnectedNetwork = useCallback(
        async ({ networkId }: SetConnectedNetworkProps) => {
            // Chain doesn't support network switching, ie, Solana
            if (!primaryWallet?.connector?.supportsNetworkSwitching()) {
                handleNetworkChange(networkId);
                return Promise.resolve(true);
            }

            setIsNetworkSetting(true);

            try {
                await primaryWallet?.connector.switchNetwork({
                    networkChainId: toNetworkId(networkId),
                });
                return Promise.resolve(true);
            } catch (error) {
                console.error(`Failed to switch network`, error);
                return Promise.resolve(false);
            } finally {
                setIsNetworkSetting(false);
            }
        },
        [primaryWallet?.connector, handleNetworkChange]
    );

    // Setup network on walletConnector setup or change
    useEffect(() => {
        if (!primaryWallet?.connector) {
            setNetwork(null);
            return;
        }

        (async () => {
            const networkId = await primaryWallet?.connector.getNetwork();
            handleNetworkChange(networkId);
        })();
    }, [primaryWallet?.connector, handleNetworkChange]);

    // Network has changed
    useWalletConnectorEvent(
        primaryWallet?.connector ? [primaryWallet?.connector] : [],
        "chainChange",
        ({ chain }) => {
            handleNetworkChange(chain);
        }
    );

    return { network, isNetworkSetting, setConnectedNetwork };
};

export default useWalletNetwork;
