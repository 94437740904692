import { useCallback, useState } from "react";
import { formatUnits } from "ethers";
import { toNumber } from "utils/numbers";
import { CheckoutToken } from "checkout/types";
import { CheckoutContractResponse } from "api/types/checkout";
import { useSafeApi } from "hooks/useSafeApi";
import { useWallet } from "context/Wallet";

const useCheckoutAllowance = (minimumAllowanceInToken: number) => {
    const { walletConnected, getTokenAllowance, addToTokenAllowance } =
        useWallet();
    const { sendApproveAllownaceForSafe } = useSafeApi();

    const [hasMinimumAllowance, setHasMinimumAllowance] =
        useState<boolean>(false);
    const [currentAllowance, setCurrentAllowance] = useState<number>(0);

    const updateHasAllowance = useCallback(
        async (
            token?: CheckoutToken,
            contract?: CheckoutContractResponse
        ): Promise<{ hasEnough: boolean; minimumAllowanceInToken: number }> => {
            if (!token || !contract)
                throw new Error(`Unable to check allowance`);

            if (contract.networkId !== token.networkId)
                throw new Error(
                    `Contract network ${contract.networkId} does not match token network ${token.networkId}`
                );

            try {
                const currentAllowance = await getTokenAllowance({
                    contractAddress: contract.address,
                    tokenAddress: token.address,
                    force: true,
                });

                const allowance = formatUnits(currentAllowance, token.decimals);

                if (allowance) setCurrentAllowance(toNumber(allowance));
                else throw new Error(`Could not verify your allowance amount`);

                const allowanceAmountLowerThanMinimum =
                    toNumber(allowance) < minimumAllowanceInToken;

                setHasMinimumAllowance(!allowanceAmountLowerThanMinimum);
                return Promise.resolve({
                    hasEnough: !allowanceAmountLowerThanMinimum,
                    minimumAllowanceInToken,
                });
            } catch (error) {
                setCurrentAllowance(0);
                setHasMinimumAllowance(false);
                return Promise.resolve({
                    hasEnough: false,
                    minimumAllowanceInToken,
                });
            }
        },
        [minimumAllowanceInToken, getTokenAllowance]
    );

    const sendAllowance = useCallback(
        async (
            allowanceAmount?: string,
            token?: CheckoutToken,
            contract?: CheckoutContractResponse
        ) => {
            if (!allowanceAmount || !token || !contract)
                throw new Error(`Unable to send allowance`);

            if (!walletConnected)
                throw new Error(`Wallet must be connected to send allowance`);

            if (walletConnected.proxyFor) {
                return await sendApproveAllownaceForSafe({
                    token: token,
                    contract: contract.address,
                    amount: allowanceAmount,
                })
                    .then((success) => {
                        return Promise.resolve(success);
                    })
                    .catch(() => {
                        throw new Error(`Allowance was not updated`);
                    });
            }

            const wasUpdated = await addToTokenAllowance({
                tokenAddress: token.address,
                contractAddress: contract.address,
                amount: allowanceAmount,
                decimals: token.decimals,
                awaitConfirm: true,
            });

            if (wasUpdated === null)
                return Promise.reject(`Allowance was not updated`);

            const { hasEnough, minimumAllowanceInToken } =
                await updateHasAllowance(token, contract).catch((error) => {
                    throw new Error(error);
                });

            const formattedMinimumAllowanceInToken = parseFloat(
                minimumAllowanceInToken.toFixed(5)
            );

            return hasEnough
                ? Promise.resolve(`Allowance updated successfully`)
                : Promise.reject(
                      `Your allowance is not enough to cover the first payment. You need at least '${formattedMinimumAllowanceInToken}'.`
                  );
        },
        [
            walletConnected,
            updateHasAllowance,
            sendApproveAllownaceForSafe,
            addToTokenAllowance,
        ]
    );

    return {
        hasMinimumAllowance,
        updateHasAllowance,
        sendAllowance,
        currentAllowance,
    };
};

export { useCheckoutAllowance };
