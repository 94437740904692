import React, {
    createContext,
    useContext,
    ReactNode,
    useState,
    useCallback,
} from "react";
import { availableNetworks } from "default-variables";
import { deferredPromise } from "utils/async";
import { toNetworkHex } from "utils/numbers";
import { createPublicClient, http } from "viem";

interface EnsContextType {
    ensTable: EnsRecord[];
    lookupEnsRecords: (addresses: string[]) => Promise<void>;
    getEnsRecord: (addr: string) => EnsRecord | undefined;
    getRecordByName: (search: string) => Promise<EnsRecord | undefined>;
    getRecordsByName: (search: string) => Promise<EnsRecord[]>;
}

const EnsContext = createContext<EnsContextType | undefined>(undefined);

interface EnsProviderProps {
    children: ReactNode;
}

export interface EnsRecord {
    address: string;
    name: Promise<string | null>;
    resolveEns: (name: string | null) => void;
    rejectEns: (reason?: Error) => void;
}

export const EnsProvider: React.FC<EnsProviderProps> = ({ children }) => {
    const [ensTable, setEnsTable] = useState<EnsRecord[]>([]);

    // Receive an address and return the ENS record
    const getEnsRecord = useCallback(
        (addr: string): EnsRecord | undefined => {
            return ensTable.find(
                ({ address }) => address?.toLowerCase() === addr?.toLowerCase()
            );
        },
        [ensTable]
    );

    const getRecordByName = useCallback(
        async (search: string): Promise<EnsRecord | undefined> => {
            // Map over ensTable to resolve it into an array of records
            const allRecords = await Promise.all(
                ensTable.map(async (record): Promise<EnsRecord | undefined> => {
                    // Wait for the name to resolve
                    const name = await record.name;

                    // Check if the name matches the search string (case-insensitive)
                    return name?.toLowerCase() === search.toLowerCase()
                        ? record
                        : undefined;
                })
            );

            // Find the first resolved record that isn't undefined
            return allRecords.find((record) => !!record);
        },
        [ensTable]
    );

    // Receive a name and return an array of matching ENS records
    const getRecordsByName = useCallback(
        async (search: string): Promise<EnsRecord[]> => {
            // Map over ensTable to resolve it into an array of records
            const allRecords = await Promise.all(
                ensTable.map(async (record): Promise<EnsRecord | undefined> => {
                    // Wait for the name to resolve
                    const name = await record.name;

                    // Check if the name contains the search string (case-insensitive)
                    return name?.toLowerCase().includes(search.toLowerCase())
                        ? record
                        : undefined;
                })
            );

            // Filter out undefined entries
            return allRecords.filter((record): record is EnsRecord => !!record);
        },
        [ensTable]
    );

    // Receive an array of addresses and queue them for lookup, then store there result in the ensTable
    const lookupEnsRecords = useCallback(
        async (addresses: string[]) => {
            // Always uses mainnet for ENS lookup
            const network = availableNetworks.find(
                (network) => network.networkId === toNetworkHex(1)
            );

            if (!network || !network.viemChain) {
                return Promise.reject(`Network is not available.`);
            }

            const client = createPublicClient({
                chain: network.viemChain,
                transport: http(network.rpcUrl, {
                    batch: true,
                }),
            });

            // Filter out addresses already in ensTable, then queue up for search
            const ensBatch = addresses
                .filter((address) => {
                    return !getEnsRecord(address);
                })
                .map((address) => {
                    const [promiseName, resolveEns, rejectEns] =
                        deferredPromise<string | null>();

                    const newRecord: EnsRecord = {
                        address,
                        name: promiseName,
                        resolveEns,
                        rejectEns,
                    };

                    setEnsTable((prevTable) => {
                        return [...prevTable, newRecord];
                    });

                    return client
                        .getEnsName({ address: address as EthereumAddress })
                        .then(
                            // Name is resolved, update the record
                            (name: string | null) => {
                                resolveEns(name);
                            },
                            (error: any) => {
                                rejectEns(error);
                            }
                        );
                });

            if (!ensBatch.length) return Promise.resolve();

            // Send a single Batch JSON-RPC HTTP request to the RPC Provider.
            const results = await Promise.allSettled([...ensBatch]);

            if (results.some(({ status }) => status === "rejected")) {
                return Promise.reject(`One or more ENS lookups failed.`);
            }

            return Promise.resolve();
        },
        [ensTable, getEnsRecord]
    );

    return (
        <EnsContext.Provider
            value={{
                ensTable,
                lookupEnsRecords,
                getEnsRecord,
                getRecordByName,
                getRecordsByName,
            }}
        >
            {children}
        </EnsContext.Provider>
    );
};

export const useEns = (): EnsContextType => {
    const context = useContext(EnsContext);
    if (!context) {
        throw new Error("useEns must be used within a EnsProvider");
    }
    return context;
};
