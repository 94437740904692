import {
    Commitment,
    BlockheightBasedTransactionConfirmationStrategy,
    RpcResponseAndContext,
    SignatureResult,
} from "@solana/web3.js";
import { SolProvider } from "types/common";

export const clusterToNetworkId = (
    cluster: string
): `0x${string}` | undefined => {
    switch (cluster) {
        case `mainnet`:
            return `0x384`;
        case `devnet`:
            return `0x385`;
    }
};

export const networkIdToCluster = (
    networkId: string
): `mainnet` | `devnet` | undefined => {
    switch (networkId) {
        case `0x384`:
            return `mainnet`;
        case `0x385`:
            return `devnet`;
    }
};

export const confirmTransactionWithTimeout = async (
    provider: SolProvider,
    signature: string,
    commitment: Commitment,
    timeoutMs: number = 5000
): Promise<void> => {
    const confirmationPromise = provider.confirmTransaction(
        {
            signature,
        } as BlockheightBasedTransactionConfirmationStrategy,
        commitment
    );
    const timeoutPromise = new Promise<never>((_, reject) =>
        setTimeout(() => reject(new Error("Confirmation timed out")), timeoutMs)
    );

    const { value }: RpcResponseAndContext<SignatureResult> =
        await Promise.race([confirmationPromise, timeoutPromise]);

    if (value?.err) {
        throw value.err;
    }
    return;
};

export const confirmTransactionWithRetries = async (
    provider: SolProvider,
    signature: string,
    commitment: Commitment = "confirmed",
    attemptTimeoutsMs: number[] = [],
    delayMs: number = 100
): Promise<void> => {
    if (!attemptTimeoutsMs.length) attemptTimeoutsMs = [5000]; // default

    const attemptConfirmation = async (attempt: number): Promise<void> => {
        return confirmTransactionWithTimeout(
            provider,
            signature,
            commitment,
            attemptTimeoutsMs[attempt]
        ).catch((error) => {
            if (attempt >= attemptTimeoutsMs.length - 1) {
                throw new Error(
                    `Transaction confirmation failed after ${attemptTimeoutsMs.length} attempt(s): ${error.message}`
                );
            }

            // Wait for delayMs before retrying
            return new Promise<void>((resolve) =>
                setTimeout(resolve, delayMs)
            ).then(() => attemptConfirmation(attempt + 1));
        });
    };

    return attemptConfirmation(0);
};
